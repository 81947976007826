import '../scss/style.scss';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade, Thumbs } from 'swiper';
import { gsap } from "gsap";
import 'swiper/swiper-bundle.min.css';
import Glide from '@glidejs/glide';
import Picker from 'pickerjs';
import Sticky from 'sticky-js';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";

//import /assets/images/closered.svg
import closeredSrc from '../assets/images/closered.svg';

document.addEventListener('DOMContentLoaded', function () {

    var galleries = document.querySelectorAll('.swiper-slide[data-gallery-id]');

    galleries.forEach(function (gallery) {
        var galleryId = gallery.getAttribute('data-gallery-id');
        var gallerySelector = '[data-fancybox="video-gallery' + galleryId + '"]';
        Fancybox.bind(gallerySelector);
    });

    Fancybox.bind('[data-fancybox="videog"]');

    (function () {
        "use strict";
        const forms = document.querySelectorAll(".needs-validation");
        Array.prototype.slice.call(forms).forEach(function (form) {
          form.addEventListener(
            "submit",
            function (event) {
              if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
      
                form.querySelectorAll(":invalid")[0].focus();
              } else {
                const formData = new FormData(form);
                event.preventDefault();
                event.stopPropagation();
                const object = {};
                formData.forEach((value, key) => {
                  object[key] = value;
                });
              }
              form.classList.add("was-validated");
            },
            false
          );
        });
    })();

    const tabCities = document.querySelectorAll('.tab-city');
    const tabContents = document.querySelectorAll('.tab-content');

    tabCities.forEach((tabCity, index) => {
        tabCity.addEventListener('click', () => {

            tabContents.forEach(content => {
                content.classList.add('hidden');
            });

            tabContents[index].classList.remove('hidden');
            tabContents[index].classList.add('grid');

            tabCities.forEach(city => {
                city.classList.remove('active');
            });
            tabCity.classList.add('active');
        });
    });

    if(document.querySelector('.close-modal-form')) {
        const closeModalButton = document.querySelector(".close-modal-form");
        const modal = document.querySelector(".modal-form");
        closeModalButton.addEventListener("click", function (e) {
            e.preventDefault();
            modal.classList.remove("flex");
            modal.classList.add("hidden");
        });
    }

    if(document.querySelector('#two-step-form')) {
        const form = document.getElementById("two-step-form");
        const modals = document.querySelector(".modal-form");
        const step1 = document.getElementById("step1");
        const step2 = document.getElementById("step2");
        const active1 = document.querySelector('.active-one');
        const active2 = document.querySelector('.active-two');
        const nextStep1Button = document.getElementById("nextStep1");
        const prevStep2Button = document.getElementById("prevStep2");
        const submitFormButton = document.getElementById("submitForm");
        const resetButtons = document.querySelectorAll(".reset-btn");

        resetButtons.forEach((resetButton) => {
            resetButton.addEventListener("click", function (e) {
                e.preventDefault();
                step1.classList.remove("hidden");
                step2.classList.add("hidden");
                modals.classList.remove("flex");
                modals.classList.add("hidden");
                form.reset();
            });
        });

        nextStep1Button.addEventListener("click", function (e) {
            e.preventDefault();
            if (validateFields(step1)) {
                step1.classList.add("hidden");
                step2.classList.remove("hidden");
                active1.classList.add("select-step");
                active2.classList.remove("none-active");
            }
        });

        prevStep2Button.addEventListener("click", function (e) {
            e.preventDefault();
            step2.classList.add("hidden");
            step1.classList.remove("hidden");
        });

        /*submitFormButton.addEventListener("click", function (e) {
            e.preventDefault();
            if (validateFields(step2)) {
                modals.classList.remove("hidden");
                modals.classList.add("flex");
            }
        });*/

        function validateFields(step) {
            const fields = step.querySelectorAll("input[required]");
            for (let field of fields) {
                if (!field.checkValidity()) {
                    field.reportValidity();
                    form.classList.add("was-validated");
                    return false;
                }
            }
            return true;
        }
    }

    if(document.querySelector('.animation-enter')) {
        var animatedElement = document.querySelector(".animation-enter");

        animatedElement.classList.add("initial");

        animatedElement.addEventListener("transitionend", function() {
            animatedElement.classList.remove("animation-enter");
        });
    }

    let sticky = new Sticky('.col-fixed');

    var openSearchButton = document.querySelector(".open-search");
    var closeSearchButton = document.querySelector(".cap-close");
    var searchModal = document.querySelector(".search-modal");

    openSearchButton.addEventListener("click", function() {
        searchModal.classList.add("block");
        searchModal.classList.remove("hidden");
        closeSearchButton.classList.remove("hidden");
    });

    closeSearchButton.addEventListener("click", function() {
        searchModal.classList.remove("block");
        searchModal.classList.add("hidden");
    });

    if(document.querySelector('.story__slide')) {
        // Selecciona todos los elementos .story__slider en la página
        const sliders = document.querySelectorAll('.story__slider');

        sliders.forEach((slider) => {
            const videos = slider.querySelectorAll('.story__slide video');
            videos.forEach((video) => {
                const parentSlide = video.closest('.story__slide');
                parentSlide.setAttribute('data-swiper-autoplay', video.duration * 1000);
            });

            const swiper = new Swiper(slider, {
                modules: [Navigation, Pagination, Autoplay, EffectFade],
                speed: 1,
                watchSlidesProgress: true,
                loop: true,
                autoplay: {
                    delay: 15000,
                    disableOnInteraction: false,
                },
                slidesPerView: 1,
                navigation: {
                    nextEl: slider.querySelector(".story__next"),
                    prevEl: slider.querySelector(".story__prev"),
                },
                pagination: {
                    el: slider.querySelector('.story__pagination'),
                    renderBullet: function (index, className) {
                        return '<div class="' + className + '"> <div class="swiper-pagination-progress"></div> </div>';
                    }
                },
                on: {
                    autoplayTimeLeft(swiper, time, progress) {
                        let currentSlide = slider.querySelectorAll('.swiper-slide')[swiper.activeIndex]
                        let currentBullet = slider.querySelectorAll('.swiper-pagination-progress')[swiper.realIndex]
                        let fullTime = swiper.params.autoplay.delay;

                        let percentage = Math.min(Math.max(parseFloat(((fullTime - time) * 100 / fullTime).toFixed(1)), 0), 100) + '%';
                        gsap.set(currentBullet, {width: percentage});
                    },
                    transitionEnd(swiper) {
                        let allBullets = slider.querySelectorAll('.swiper-pagination-progress');
                        allBullets = Array.from(allBullets);

                        let bulletsBefore = allBullets.slice(0, swiper.realIndex);
                        let bulletsAfter = allBullets.slice(swiper.realIndex, allBullets.length);

                        if (bulletsBefore.length) {
                            gsap.set(bulletsBefore, { width: '100%' });
                        }
                        if (bulletsAfter.length) {
                            gsap.set(bulletsAfter, { width: '0%' });
                        }

                        let activeSlide = slider.querySelectorAll('.swiper-slide')[swiper.realIndex];
                        if (activeSlide.querySelector('video')) {
                            activeSlide.querySelector('video').currentTime = 0;
                        }
                    },
                }
            });
        });
    }

    if(document.querySelector('#hourPicker')) {
        const hourPicker = new Picker(document.getElementById('hourPicker'), {
            format: 'HH',
            rows: 5,
            text: { title: 'Choose hour' },
            max: 23,
        });
        const minutePicker = new Picker(document.querySelector('#minutePicker'), {
            format: 'mm',
            rows: 5,
            text: { title: 'Choose minute' },
            max: 59,
        });
        const secondPicker = new Picker(document.getElementById('secondPicker'), {
            format: 'ss',
            rows: 5,
            text: { title: 'Choose second' },
            max: 59,
        });
        let interval;
        let timeRemaining = 0;
        let isRunning = false;
        let startTime = 0;
        document.querySelector('#hourPicker').addEventListener('change', function () {
            timeRemaining = calcTimeRemaining();
            if (interval) {
                clearInterval(interval);
                interval = null;
                isRunning = false;
                document.querySelector('#startButton').click();
            }
            let hours = parseInt(document.querySelector('#hourPicker').value) || 0;
            hours = hours.toString().padStart(2, '0');
            document.getElementById('display').firstChild.nodeValue = hours;
        });
        document.querySelector('#minutePicker').addEventListener('change', function () {
            timeRemaining = calcTimeRemaining();
            if (interval) {
                clearInterval(interval);
                interval = null;
                isRunning = false;
                document.querySelector('#startButton').click();
            }
            let minutes = parseInt(document.querySelector('#minutePicker').value) || 0;
            minutes = minutes.toString().padStart(2, '0');
            document.querySelector('#display').childNodes[2].nodeValue = minutes;
        });
        document.querySelector('#secondPicker').addEventListener('change', function () {
            timeRemaining = calcTimeRemaining();
            if (interval) {
                clearInterval(interval);
                interval = null;
                isRunning = false;
                document.querySelector('#startButton').click();
            }
            let seconds = parseInt(document.querySelector('#secondPicker').value) || 0;
            seconds = seconds.toString().padStart(2, '0');
            document.querySelector('#display').lastChild.nodeValue = seconds;
        });
        document.querySelector('#startButton').addEventListener('click', () => {
            if (!isRunning) {
                let totalTime = calcTimeRemaining();
                console.log("#Total time", totalTime);
                if (totalTime > 0 && !interval) {
                    if (startTime === 0) {
                        timeRemaining = totalTime;
                    }
                    interval = setInterval(() => {
                        timeRemaining -= 1;
                        displayTime();
                        if (timeRemaining <= 0) {
                            clearInterval(interval);
                            isRunning = false;
                        }
                    }, 1000);
                    isRunning = true;
                } else {
                    isRunning = false;
                }
                
            }
        });
        function calcTimeRemaining() {
            const hours = parseInt(document.getElementById('hourPicker').value) || 0;
            const minutes = parseInt(document.getElementById('minutePicker').value) || 0;
            const seconds = parseInt(document.getElementById('secondPicker').value) || 0;
            return hours * 3600 + minutes * 60 + seconds;
        }
        document.getElementById('stopButton').addEventListener('click', () => {
            if (isRunning) {
                clearInterval(interval);
                interval = null;
                isRunning = false;
                startTime = calcTimeRemaining();
            }
        });
        function displayTime() {
            const hours = Math.floor(timeRemaining / 3600);
            const minutes = Math.floor((timeRemaining % 3600) / 60);
            const seconds = timeRemaining % 60;
            const displayElement = document.getElementById('display');
            displayElement.innerHTML =
                String(hours).padStart(2, '0') + '<span class="mt-[-.4rem]"> : </span>' +
                String(minutes).padStart(2, '0') + '<span class="mt-[-.4rem]"> : </span>' +
                String(seconds).padStart(2, '0');
        }
    }

    if (document.querySelector('.close-modal-link')) {
        const openModalLinks = document.querySelectorAll('.open-modal-link');
        const closeModalLink = document.querySelector('.close-modal-link');
        const modalFormLink = document.querySelector('.modal-form-link');

        function openModal() {
            modalFormLink.classList.add('flex');
            modalFormLink.classList.remove('hidden');
        }

        function closeModal() {
            modalFormLink.classList.remove('flex');
            modalFormLink.classList.add('hidden');
        }

        openModalLinks.forEach(function (link) {
            link.addEventListener('click', function (event) {
                event.preventDefault();
                openModal();
            });
        });

        closeModalLink.addEventListener('click', function (event) {
            event.preventDefault();
            closeModal();
        });
    }

    if (document.querySelector('#attached-photos')) {
        const fileInput = document.querySelector('#attached-photos');
        const filePreview = fileInput.closest('.file-preview-container').querySelector('.file-preview');
        const messageContainer = document.querySelector('.msn_file');
    
        fileInput.addEventListener('change', function (event) {
            filePreview.innerHTML = '';
            if (fileInput.files.length > 0) {
                filePreview.classList.add('flex');
                filePreview.classList.remove('hidden');
    
                Array.from(fileInput.files).forEach(function (file) {
                    const fileName = file.name;
    
                    const fileNameElement = document.createElement('span');
                    fileNameElement.textContent = fileName;
    
                    const deleteButton = document.createElement('button');
                    deleteButton.innerHTML = '<i class="fa-solid fa-xmark text-[1.5rem] mt-[.3rem]"></i>';
    
                    deleteButton.addEventListener('click', function () {
                        filePreview.removeChild(fileItemContainer);
                        if (filePreview.children.length === 0) {
                            filePreview.classList.remove('flex');
                            filePreview.classList.add('hidden');
                            messageContainer.classList.remove('hidden');
                        }
                    });
    
                    const fileItemContainer = document.createElement('div');
                    fileItemContainer.classList.add('rounded-[2rem]', 'bg-[#FAFAFA]', 'px-[1.6rem]', 'flex', 'items-center', 'gap-x-[1rem]', 'py-[1rem]');
                    fileItemContainer.appendChild(fileNameElement);
                    fileItemContainer.appendChild(deleteButton);
    
                    filePreview.appendChild(fileItemContainer);
    
                    messageContainer.classList.add('hidden');
                });
            } else {
                filePreview.classList.remove('flex');
                filePreview.classList.add('hidden');
                messageContainer.classList.remove('hidden');
            }
        });
    }
    
    if(document.querySelector('.glide.minigallery')) {
        const minig = new Glide('.glide.minigallery', {
            type: 'carousel',
            startAt: 0,
            gap: 0,
            focusAt: 'center',
            perView: 1,
            peek: 399,
            breakpoints: {
                1100: {
                    peek: 640,
                },
                960: {
                    peek: 430,
                },
                680: {
                    peek: 170,
                },
                380: {
                    peek: 145,
                }
            }
        }).mount();
    }

    if(document.querySelector('.glide.red-slider')) {
        const sl1 = new Glide('.glide.red-slider', {
            type: 'carousel',
            startAt: 0,
            gap: 0,
            focusAt: 'center',
            autoplay: 5000,
            perView: 1,
            peek: 399,
            breakpoints: {
                1100: {
                    peek: 640,
                },
                960: {
                    peek: 430,
                },
                680: {
                    peek: 170,
                },
                380: {
                    peek: 145,
                }
            }
        }).mount();
        
        const sl2 = new Glide('.glide2', {
            type: 'carousel',
            startAt: 0,
            gap: 0,
            focusAt: 'center',
            autoHeight: false,
            breakpoints: {
                680: {
                    autoHeight: true,
                }
            }
        }).mount();
        
        document.querySelectorAll('.glide__slide[data-index]').forEach(function (element) {
            element.addEventListener('click', function (e) {
                var ind = parseInt(element.getAttribute('data-index'));
        
                if (!isNaN(ind)) {
                    console.log(ind);
                    sl1.go('=' + ind);
                }

                var ind2 = parseInt(element.getAttribute('data-index'));
        
                if (!isNaN(ind2)) {
                    console.log(ind2);
                    sl2.go('=' + ind2);
                }
            });
        });
        
        sl1.on('run', (e) => {
            sl2.go(e.direction);
        });
        
        sl2.on('run', (e) => {
            sl1.go(e.direction);
        });
    }

    var slider2 = new Swiper('#slider1', {
        modules: [Navigation, Pagination, Autoplay, EffectFade],
        loop: true,
        autoplay: {
            delay: 3000,
        },
        pagination: {
            el: '.swiper-pagination2',
            clickable: true,
        },
    });

    var sliderfullw = new Swiper('#slider-fullw', {
        // Configuración del primer slider
        autoHeight: true,
        breakpoints: {
            0: {
                autoHeight: true,
            },
            640: {
              slidesPerView: 1,
            },
            900: {
                autoHeight: true,
            },
        }
    });

    var titles = document.querySelectorAll('.accordion-title');
    var contents = document.querySelectorAll('.accordion-content');
    var icons = document.querySelectorAll('.accordion-icon');

    titles.forEach(function (title, index) {
        title.addEventListener('click', function () {
            var content = contents[index];
            var icon = icons[index];

            if (content.classList.contains('active')) {
                content.classList.remove('active');
                icon.textContent = '+';
                title.classList.remove('active');
            } else {
                closeAllAccordions();
                
                content.classList.add('active');
                icon.textContent = '-';
                title.classList.add('active');
            }
        });
    });

    function closeAllAccordions() {
        contents.forEach(function (content) {
            content.classList.remove('active');
        });

        icons.forEach(function (icon) {
            icon.textContent = '+';
        });

        titles.forEach(function (title) {
            title.classList.remove('active');
        });
    }

    var openButtons = document.querySelectorAll('.open-acor');

    openButtons.forEach(function (button) {
        button.addEventListener('click', function () {
            var parentAcordion = button.closest('.parent-acor');
            var contentGrid = parentAcordion.nextElementSibling;

            if (contentGrid && contentGrid.classList.contains('content-acor-grid')) {
                var mainParent = parentAcordion.closest('.main-parent');

                if (mainParent.classList.contains('active')) {
                    mainParent.classList.remove('active');
                } else {
                    closeAllAccordions();
                    mainParent.classList.add('active');
                }
            }
        });
    });

    function closeAllAccordions() {
        var activeParents = document.querySelectorAll('.main-parent.active');
        
        activeParents.forEach(function (activeParent) {
            var contentGrid = activeParent.querySelector('.content-acor-grid');
            if (contentGrid) {
                activeParent.classList.remove('active');
            }
        });
    }

    const tabCities2 = document.querySelectorAll('.tab-city2');
    const tabContents2 = document.querySelectorAll('.tab-content2');

    tabCities2.forEach((tabCity2, index2) => {
        tabCity2.addEventListener('click', () => {

            tabContents2.forEach(content => {
                content.classList.add('hidden');
            });

            
            tabContents2[index2].classList.remove('hidden');
            tabContents2[index2].classList.add('grid');

            tabCities2.forEach(city => {
                city.classList.remove('active');
            });
            tabCity2.classList.add('active');
        });
    });

    const groupGallerySelects = document.querySelectorAll('.year-slider-select');
    const sliderGroup = document.querySelectorAll('.slider-group');
    groupGallerySelects.forEach(function(select, index) {
        select.addEventListener('change', function () {
            sliderGroup.forEach(function(element, i){
                element.classList.add('hidden');
            });

            const sliderGroupContainer = document.querySelector('.slider-group-' + select.value);
            sliderGroupContainer.classList.remove('hidden');

            const sliderFilter = sliderGroupContainer.querySelector('.slider-filter');
            sliderFilter.value = select.value;
        });
    });

    var slider3 = new Swiper('#cards', {
        modules: [Navigation, Pagination, Autoplay, EffectFade],
        slidesPerView: 2,
        spaceBetween: 22,
        navigation: {
            nextEl: '.swiper-button-next-cards',
            prevEl: '.swiper-button-prev-cards',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 1,
            },
            900: {
                slidesPerView: 1,
            },
            1100: {
                slidesPerView: 2,
            }
        }
    });

    var slider3 = new Swiper('.retails-mini-slider', {
        modules: [Navigation, Pagination, Autoplay, EffectFade],
        slidesPerView: 1,
        spaceBetween: 22,
        navigation: {
            nextEl: '.retails-mini-prev2',
            prevEl: '.retails-mini-next2',
        },
        breakpoints: {
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            900: {
                slidesPerView: 2,
            },
            1100: {
                slidesPerView: 2,
            }
        }
    });

    

    var foodSliders = document.querySelectorAll('.food-slider');

    foodSliders.forEach(function(sliderContainer) {
        var foodSlider = new Swiper(sliderContainer, {
            modules: [Navigation, Pagination, Autoplay, EffectFade],
            slidesPerView: 1,
            spaceBetween: 22,
            navigation: {
                nextEl: sliderContainer.parentNode.querySelector('.swiper-food-next2'),
                prevEl: sliderContainer.parentNode.querySelector('.swiper-food-prev2'),
            },
            pagination: {
                el: sliderContainer.parentNode.querySelector('.swiper-pagination-food'),
                clickable: true,
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                640: {
                    slidesPerView: 1,
                },
                900: {
                    slidesPerView: 1,
                },
                1100: {
                    slidesPerView: 1,
                }
            }
        });
    });

    var slider4 = new Swiper('#slider-mini-hover', {
        modules: [Navigation, Pagination, Autoplay, EffectFade],
        slidesPerView: 3,
        spaceBetween: 22,
        centeredSlides: true,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next-mini-hover',
            prevEl: '.swiper-button-prev-mini-hover',
        },
        breakpoints: {
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 3,
            },
            900: {
                slidesPerView: 3,
            },
            1100: {
                slidesPerView: 3,
            },
            1950: {
                slidesPerView: 4,
            }
        }
    });

    /*if (document.querySelector('.gridM')) {
        var masonry = new Masonry('.gridM', {
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true,
            gutter: 0
        });
    }*/

    var link_show = document.querySelectorAll('.link-show');
    if (link_show.length > 0) {
        var link_show = document.querySelectorAll('.link-show');
        link_show.forEach(function(link, index) {
            link.addEventListener('click', function() {
                var contents = document.querySelectorAll('.tabs-info .content');
                
                link_show.forEach(function(link) {
                    link.classList.remove('active');
                });
                contents.forEach(function(content) {
                    content.classList.remove('active');
                });
                link.classList.add('active');
                contents[index].classList.add('active');
            });
        });
        link_show[0].click();
    }

    const fixedBrands = document.querySelector('.fixed-brands');
    const openFixed = document.querySelector('.open-fixed');

    openFixed.addEventListener('click', function() {
        fixedBrands.classList.toggle('fixed-brands-animate');
        openFixed.classList.toggle('mobile-class');
    });

    document.addEventListener('click', function(event) {
        const target = event.target;
        if (target !== openFixed && !openFixed.contains(target)) {
            fixedBrands.classList.remove('fixed-brands-animate');
            openFixed.classList.toggle('mobile-class');
        }
    });
    
    //fixed-brandsMobile

    const fixedBrandsMobile = document.querySelector('.fixed-brandsMobile');
    const openFixedMobile = document.querySelector('.open-fixedMobile');

    openFixedMobile.addEventListener('click', function() {
        fixedBrandsMobile.classList.toggle('fixed-brands-animateMobile');
        openFixedMobile.classList.toggle('mobile-class');
    });

    document.addEventListener('click', function(event) {
        const target = event.target;
        if (target !== openFixedMobile && !openFixedMobile.contains(target)) {
            fixedBrandsMobile.classList.remove('fixed-brands-animateMobile');
            openFixedMobile.classList.toggle('mobile-class');
        }
    });


    //fixed-background
    const backgroundFixed = document.querySelector('.fixed-background');
    const openBackgroundFixed = document.querySelector('.open-fondo-fixed');

    openBackgroundFixed.addEventListener('click', function() {
        backgroundFixed.classList.toggle('fixed-brands-background');
        openBackgroundFixed.classList.toggle('mobile-background');
    });

    document.addEventListener('click', function(event) {
        const target = event.target;
        if (target !== openBackgroundFixed && !openBackgroundFixed.contains(target)) {
            backgroundFixed.classList.remove('fixed-brands-background');
            openBackgroundFixed.classList.toggle('mobile-background');
        }
    });

    let openMobileElements = document.querySelectorAll('.open-mobile');
    openMobileElements.forEach(function(openMobileElement) {
        openMobileElement.addEventListener('click', function(event) {
            event.preventDefault();
            let parentElement = this.parentNode;
            let subMenu = parentElement.parentElement.querySelector('.sub-menu');
            if(subMenu.classList.contains('open')) {
                subMenu.classList.remove('open');
                this.classList.remove('rotated');
            } else {
                let openSubMenus = document.querySelectorAll('.sub-menu.open');
                let rotatedElements = document.querySelectorAll('.open-mobile.rotated');
                openSubMenus.forEach(function(openSubMenu) {
                    openSubMenu.classList.remove('open');
                });
                rotatedElements.forEach(function(rotatedElement) {
                    rotatedElement.classList.remove('rotated');
                });
                subMenu.classList.add('open');
                this.classList.add('rotated');
            }
        });
    });

    let openMobileMenu = document.querySelector('.open-mobile-menu');
    openMobileMenu.addEventListener('click', function(event) {
        event.preventDefault();
        let navigationMainMobile = document.querySelector('.navigation-main-mobile');
        if(navigationMainMobile.classList.contains('open-navigation')) {
            navigationMainMobile.classList.remove('open-navigation');
        } else {
            navigationMainMobile.classList.add('open-navigation');
            navigationMainMobile.classList.remove('close-navigation');
        }
    });

    let closeMobileMenu = document.querySelector('.close-mobile-menu');
    closeMobileMenu.addEventListener('click', function(event) {
        event.preventDefault();
        let navigationMainMobile = document.querySelector('.navigation-main-mobile');
        if(navigationMainMobile.classList.contains('close-navigation')) {
            navigationMainMobile.classList.remove('close-navigation');
        } else {
            navigationMainMobile.classList.add('close-navigation');
            navigationMainMobile.classList.remove('open-navigation');
        }
    });

    const accHeads = document.querySelectorAll(".acc-head");
    const subAccHeads = document.querySelectorAll(".sub-head");
    const accContents = document.querySelectorAll(".acc-content");
    const subAccContents = document.querySelectorAll(".sub-acc-content");

    accHeads.forEach((accHead, index) => {
        accHead.addEventListener("click", () => {
            const isAccOpen = accContents[index].classList.contains("show");

            accContents.forEach((content, contentIndex) => {
                content.classList.remove("show");
                accContents[index].classList.add("hidden");
            });

            subAccContents.forEach((content, contentIndex) => {
                content.classList.remove("show");
            });

            accHeads.forEach((head) => {
                head.classList.remove("active");
            });

            subAccHeads.forEach((subHead) => {
                subHead.classList.remove("active");
            });

            if (!isAccOpen) {
                accContents[index].classList.toggle("show");
                accContents[index].classList.toggle("hidden");
                

                accHead.classList.add("active");
            }
        });
    });

    subAccHeads.forEach((subAccHead, index) => {
        subAccHead.addEventListener("click", () => {
            const isSubAccOpen = subAccContents[index].classList.contains("show");

            subAccContents.forEach((content, contentIndex) => {
                content.classList.remove("show");
            });

            subAccHeads.forEach((subHead) => {
                subHead.classList.remove("active");
            });

            if (!isSubAccOpen) {
                subAccContents[index].classList.toggle("show");
                subAccHead.classList.add("active");
            }
        });
    });

    if (document.querySelector('.gallery-thumbs')) {
        var swiperT = new Swiper(".gallery-thumbs", {
            spaceBetween: 10,
            slidesPerView: 2,
        });
        var swiperS = new Swiper(".gallery-slider", {
            modules: [Navigation, Pagination, Thumbs],
            spaceBetween: 10,
            slidesPerView: 1,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: '.swiper-pagination2',
                clickable: true,
            },
            thumbs: {
              swiper: swiperT,
            },
        });
    }

    /*var swiperTime2 = new Swiper(".times", {
        modules: [Navigation, Pagination, Autoplay, EffectFade],
        slidesPerView: 4,
        spaceBetween: 20,
        loop: true,
        autoHeight: false,
        navigation: {
            nextEl: ".swiper-button-next-time",
            prevEl: ".swiper-button-prev-time",
        },
        breakpoints: {  
            0: {
                slidesPerView: 1,
            },
            680: {
                slidesPerView: 2,
            },
            1000: {
                slidesPerView: 3,
            },
            1700: {
                slidesPerView: 3,
            },
        },
        on: {
            click(event) {
                console.log('event.target',this.clickedIndex);
                swiperTime2.slideTo(this.clickedIndex);	
                swiperTime2.update();
            },
        },
    });*/

    if (document.querySelector('.times2')) {
        const sl1_times = new Glide('.times2', {
            type: 'carousel',
            perView: 4,
            gap: 20,
            breakpoints: {
                1100: {
                    perView: 3
                },
                960: {
                    perView: 3
                },
                680: {
                    perView: 2,
                },
                480: {
                    perView: 1,
                }
            }
        }).mount();

        document.querySelector('.times2 .glide__slides').addEventListener('click', function(e) {
            var closestListItem = e.target.closest('.glide__slide');
            if (closestListItem) {
                var ind = parseInt(closestListItem.getAttribute('data-index'));
                console.log(ind)
                if (!isNaN(ind) && ind >= 0) {
                    sl1_times.go('='+ind);
                }
            }
        });
    }

    if (document.querySelector('.storys-slider')) {
        var swiperTime = new Swiper(".storys-slider", {
            modules: [Navigation, Pagination, Thumbs],
            spaceBetween: 20,
            slidesPerGroup: 1,
            slidesPerView: 2,
            direction : 'horizontal',
            freeMode: true,
            loop: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: ".swiper-button-next-story",
                prevEl: ".swiper-button-prev-story",
            },
            breakpoints: {  
                '0': {
                    slidesPerView: 1,
                },
                '680': {
                    slidesPerView: 2,
                },
            },
        });
    }

    if (document.querySelector('.slider-mini')) {
        var swiperM = new Swiper(".slider-mini", {
            spaceBetween: 20,
            slidesPerView: 6,
            freeMode: true,
            watchSlidesProgress: true,
            allowTouchMove: true,
            breakpoints: {  
                '0': {
                    slidesPerView: 3,
                },
                '680': {
                    slidesPerView: 3,
                },
                '800': {
                    slidesPerView: 5,
                },
                '900': {
                    slidesPerView: 5,
                },
                '1100': {
                    slidesPerView: 6,
                },
            },
        });
    }

    if (document.querySelector('.slider-mini-links')) {
        var swiperM = new Swiper(".slider-mini-links", {
            spaceBetween: 10,
            slidesPerView: 6,
            freeMode: true,
            watchSlidesProgress: true,
            allowTouchMove: true,
            breakpoints: {  
                '0': {
                    slidesPerView: 4,
                },
                '400': {
                    slidesPerView: 5,
                },
                '680': {
                    slidesPerView: 4,
                },
                '800': {
                    slidesPerView: 5,
                },
                '900': {
                    slidesPerView: 5,
                },
                '1100': {
                    slidesPerView: 6,
                },
            },
        });
    }

    if (document.querySelector('.slider-product-mobile')) {
        var swipermi = new Swiper(".slider-product-mobile", {
            modules: [Navigation, Pagination, Autoplay, EffectFade],
            spaceBetween: 20,
            slidesPerView: 3,
            freeMode: true,
            loop: true,
            loopedSlides: 3,
            watchSlidesProgress: true,
            allowTouchMove: true,
            navigation: {
                nextEl: '.swiper-button-next2',
                prevEl: '.swiper-button-prev2',
            },
            breakpoints: {  
                '0': {
                    slidesPerView: 1.02,
                },
                '680': {
                    slidesPerView: 2,
                },
                '800': {
                    slidesPerView: 2,
                },
                '900': {
                    slidesPerView: 2,
                },
                '1000': {
                    slidesPerView: 3,
                },
            },
        });
    }

    if (document.querySelector('.slider-cards-mobile')) {
        var swipermi2 = new Swiper(".slider-cards-mobile", {
            modules: [Navigation, Pagination, Autoplay, EffectFade],
            spaceBetween: 20,
            slidesPerView: 3,
            freeMode: true,
            loop: true,
            loopedSlides: 3,
            watchSlidesProgress: true,
            allowTouchMove: true,
            navigation: {
                nextEl: '.swiper-button-next2',
                prevEl: '.swiper-button-prev2',
            },
            breakpoints: {  
                '0': {
                    slidesPerView: 2.1,
                },
                '680': {
                    slidesPerView: 2,
                },
                '800': {
                    slidesPerView: 3,
                },
                '900': {
                    slidesPerView: 3,
                },
                '1000': {
                    slidesPerView: 4,
                },
            },
        });
    }
    
    function initializeTooltips() {
        if (document.querySelector('.tooltip-section')) {
            const tooltipSections = document.querySelectorAll(".tooltip-section");
            const tooltips = document.querySelectorAll(".tooltip");
    
            tooltipSections.forEach(function(section, index) {
                // Primero, elimina cualquier oyente de eventos previo para evitar duplicaciones
                section.removeEventListener("click", handleTooltipClick);
    
                // Luego, añade el nuevo oyente de eventos
                section.addEventListener("click", handleTooltipClick);
            });
    
            function handleTooltipClick() {
                const sectionIndex = Array.from(tooltipSections).indexOf(this);
                const tooltipVisible = !tooltips[sectionIndex].classList.contains("hidden");
    
                tooltips.forEach(function(tooltip) {
                    tooltip.classList.add("hidden");
                });
    
                if (!tooltipVisible) {
                    tooltips[sectionIndex].classList.remove("hidden");
                }
            }
        }
    }

    initializeTooltips();
    

    if (document.querySelector('.closetab')) {
        const closeButton = document.querySelectorAll(".closetab");

        closeButton.forEach(function(button) {
            button.addEventListener("click", function() {
                const tabContainer = this.parentNode.parentNode;
                const tab = this.parentNode;
                tabContainer.removeChild(tab);
            });
        });

        const clearButton = document.querySelector(".clear-btn");

        clearButton.addEventListener("click", function(e) {
            e.preventDefault();
            const checkboxes = document.querySelectorAll("input[type='checkbox']:checked");
            checkboxes.forEach(function(checkbox) {
                checkbox.checked = false;
            });
        });
    }

    

    if (document.querySelector('.open-modal-filter')) {
        const openModalButton = document.querySelector(".open-modal-filter");
        const modal = document.querySelector(".modal-filter");

        openModalButton.addEventListener("click", function() {
            modal.classList.remove("hidden");
            modal.classList.add("block");
        });

        const openModalButton2 = document.querySelector(".close-modal-filter");

        openModalButton2.addEventListener("click", function(e) {
            e.preventDefault();
            modal.classList.remove("block");
            modal.classList.add("hidden");
        });
    }

    if (document.querySelector('#apply-filter')) {
        const applyFilterButton = document.querySelector("#apply-filter");
        const modal = document.querySelector(".modal-filter");

        applyFilterButton.addEventListener("click", function() {
            modal.classList.remove("block");
            modal.classList.add("hidden");
        });
    }

    if (document.querySelector('.acc')) {
        const accContainers = document.querySelectorAll('.acc');
        
        accContainers.forEach(acc => {
            const checkboxes = acc.querySelectorAll('input[type="checkbox"]');
            
            checkboxes.forEach(checkbox => {
                checkbox.addEventListener('change', function() {
                    const isChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);
                    
                    if (isChecked) {
                        acc.classList.add('active-parent');
                    } else {
                        acc.classList.remove('active-parent');
                    }
                });
            });
        });
    }
    

    if (document.querySelector('.start')) {
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        let intervalId;

        const hoursElement = document.querySelector('.hours');
        const minutesElement = document.querySelector('.minutes');
        const secondsElement = document.querySelector('.seconds');
        const startButton = document.querySelector('.start');
        const stopButton = document.querySelector('.stop');

        function updateTimer() {
            seconds++;
            if (seconds === 60) {
                seconds = 0;
                minutes++;
                if (minutes === 60) {
                    minutes = 0;
                    hours++;
                }
            }

            hoursElement.textContent = hours.toString().padStart(2, '0');
            minutesElement.textContent = minutes.toString().padStart(2, '0');
            secondsElement.textContent = seconds.toString().padStart(2, '0');
        }

        startButton.addEventListener('click', function () {
            console.log('time', hours, minutes, seconds);
            console.log('status', startButton.disabled);
    
            if (seconds == 0 && minutes == 0 && hours == 0) {
                startButton.disabled = false;
    
                return;
            }
    
            intervalId = setInterval(updateTimer, 1000);
            startButton.disabled = true;
        });

        stopButton.addEventListener('click', function () {
            clearInterval(intervalId);
            startButton.disabled = false;
        });

        window.onload = function () {
            hoursElement.textContent = '00';
            minutesElement.textContent = '00';
            secondsElement.textContent = '00';
        };
    }

    const boxes = document.querySelectorAll(".box");

    boxes.forEach((box) => {
        box.addEventListener("click", () => {
            box.classList.toggle("check-active");
        });
    });

    function initiStar() {
        let valueHover = 0;
        function calcSliderPos(e, maxV) {
            return (e.offsetX / e.target.clientWidth) * parseInt(maxV, 10);
        }
        const starrateElements = document.querySelectorAll('.starrate');
        starrateElements.forEach(function (element) {
            element.addEventListener('click', function () {
                this.dataset.val = valueHover;
                this.classList.add('saved');
            });

            element.addEventListener('mouseout', function () {
                upStars(this.dataset.val);
            });
            const ctrlElement = element.querySelector('span.ctrl');
            ctrlElement.addEventListener('mousemove', function (e) {
                const maxV = parseInt(this.parentElement.dataset.max, 10);
                valueHover = Math.ceil(calcSliderPos(e, maxV) * 2) / 2;
                upStars(valueHover);
            });
        });
        function upStars(val) {
            const valFloat = parseFloat(val);
            const testElement = document.getElementById('test');
            testElement.textContent = valFloat.toFixed(1);

            const isFull = Number.isInteger(valFloat);
            const valInt = parseInt(valFloat, 10);
            const starIcons = document.querySelectorAll('#starrate i');
            starIcons.forEach(function (star, index) {
                if (index < valInt) {
                    star.className = 'fa-solid fa-star';
                } else if (!isFull && index === valInt) {
                    star.className = 'fa-solid fa-star-half-stroke';
                } else {
                    star.className = 'fa-regular fa-star';
                }
            });
        }
        const ctrlElements = document.querySelectorAll('.starrate span.ctrl');
        const contElements = document.querySelectorAll('.starrate span.cont');
        ctrlElements.forEach(function (ctrlElement, index) {
            ctrlElement.style.width = `${contElements[index].offsetWidth}px`;
            ctrlElement.style.height = `${contElements[index].offsetHeight}px`;
        });
    }
    

    if (document.querySelector('.open-modal-star')) {
        const openModalLinks = document.querySelectorAll('.open-modal-star');
        const closeModalLinks = document.querySelectorAll('.close-modal-star');
        const modal = document.querySelector('.modal-star');

        function openModal() {
            modal.classList.remove('hidden');
            modal.classList.add('flex');
            setTimeout(function() {
                initiStar();
            }, 100);
        }
        function closeModal() {
            modal.classList.remove('flex');
            modal.classList.add('hidden');
        }
        openModalLinks.forEach(function (link) {
            link.addEventListener('click', function (event) {
            event.preventDefault();
            openModal();
            });
        });
        closeModalLinks.forEach(function (link) {
            link.addEventListener('click', function (event) {
                event.preventDefault();
                closeModal();
            });
        });
    }

    if (document.querySelector(".audio-player")) {        
        let currentAudioIndex = 0;
        const audioPlayer = document.querySelector(".audio-player");

        const audioSources = JSON.parse(audioPlayer.dataset.tracks);
        const audio = new Audio(audioSources[currentAudioIndex]);

        function loadAudio(index) {
            audio.src = audioSources[index];
            audio.load();

            audio.play();
        }

        function playNext() {
            currentAudioIndex = (currentAudioIndex + 1) % audioSources.length;
            loadAudio(currentAudioIndex);
        }

        function playPrev() {
            currentAudioIndex = (currentAudioIndex - 1 + audioSources.length) % audioSources.length;
            loadAudio(currentAudioIndex);
        }

        console.dir(audio);

        audio.addEventListener(
            "loadeddata",
            () => {
                const timeLengthElements = audioPlayer.querySelectorAll(".time .length");
                timeLengthElements.forEach((element) => {
                    element.textContent = getTimeCodeFromNum(audio.duration);
                });

                audio.volume = 0.75;
            },
            false
        );

        const timelines = audioPlayer.querySelectorAll(".timeline");
        timelines.forEach((timeline) => {
            timeline.addEventListener("click", (e) => {
                const timelineWidth = window.getComputedStyle(timeline).width;
                const timeToSeek = (e.offsetX / parseInt(timelineWidth)) * audio.duration;
                audio.currentTime = timeToSeek;
            }, false);
        });

        const volumeSliders = audioPlayer.querySelectorAll(".controls .volume-slider");
        volumeSliders.forEach((volumeSlider) => {
            volumeSlider.addEventListener('click', (e) => {
                const sliderWidth = window.getComputedStyle(volumeSlider).width;
                const newVolume = e.offsetX / parseInt(sliderWidth);
                audio.volume = newVolume;
                const volumePercentageElements = audioPlayer.querySelectorAll(".controls .volume-percentage");
                volumePercentageElements.forEach((element) => {
                    element.style.width = newVolume * 100 + '%';
                });
            }, false);
        });

        setInterval(() => {
            const progressBarElements = audioPlayer.querySelectorAll(".progress");
            progressBarElements.forEach((element) => {
                element.style.width = (audio.currentTime / audio.duration) * 100 + "%";
            });

            const currentTimeElements = audioPlayer.querySelectorAll(".time .current");
            currentTimeElements.forEach((element) => {
                element.textContent = getTimeCodeFromNum(audio.currentTime);
            });
        }, 500);

        const playBtns = audioPlayer.querySelectorAll(".controls .toggle-play");
        playBtns.forEach((playBtn) => {
            playBtn.addEventListener("click", () => {
                if (audio.paused) {
                    playBtns.forEach((btn) => {
                        btn.classList.remove("play");
                        btn.classList.add("pause");
                    });
                    audio.play();
                } else {
                    playBtns.forEach((btn) => {
                        btn.classList.remove("pause");
                        btn.classList.add("play");
                    });
                    audio.pause();
                }
            }, false);
        });

        const volumeButtons = audioPlayer.querySelectorAll(".volume-button");
        volumeButtons.forEach((volumeButton) => {
            volumeButton.addEventListener("click", () => {
                const volumeEl = audioPlayer.querySelector(".volume-container .volume");
                audio.muted = !audio.muted;
                if (audio.muted) {
                    volumeEl.classList.remove("icono-volumeMedium");
                    volumeEl.classList.add("icono-volumeMute");
                } else {
                    volumeEl.classList.add("icono-volumeMedium");
                    volumeEl.classList.remove("icono-volumeMute");
                }
            });
        });

        function getTimeCodeFromNum(num) {
            let seconds = parseInt(num);
            let minutes = parseInt(seconds / 60);
            seconds -= minutes * 60;
            const hours = parseInt(minutes / 60);
            minutes -= hours * 60;

            if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
            return `${String(hours).padStart(2, 0)}:${minutes}:${String(
                seconds % 60
            ).padStart(2, 0)}`;
        }

        const nextBtns = audioPlayer.querySelectorAll(".controls .next");
        nextBtns.forEach((nextBtn) => {
            nextBtn.addEventListener("click", playNext);
        });

        const prevBtns = audioPlayer.querySelectorAll(".controls .prev");
        prevBtns.forEach((prevBtn) => {
            prevBtn.addEventListener("click", playPrev);
        });

        const trackList = audioPlayer.querySelectorAll(".track-list-item");
        trackList.forEach((track, index) => {
            track.addEventListener("click", () => {
                currentAudioIndex = index;
                loadAudio(currentAudioIndex);
            });
        });

        const openMobilePlay = document.querySelector('.open-mobile-play');
        const openMobilePlay2 = document.querySelector('.open-mobile-play2');
        const mobilePlay = document.querySelector('.mobile-play');
        const mobileHidden = document.querySelector('.mobile-hidden');

        openMobilePlay.addEventListener('click', () => {
            mobilePlay.classList.remove('md:flex');
            mobilePlay.classList.add('md:hidden');
            mobileHidden.classList.remove('hidden');
            mobileHidden.classList.add('flex');
        });

        openMobilePlay2.addEventListener('click', () => {
            mobilePlay.classList.remove('md:hidden');
            mobilePlay.classList.add('md:flex');
            mobileHidden.classList.remove('flex');
            mobileHidden.classList.add('hidden');
        });
    }

    if (document.querySelector(".link-video")) {
        const linkVideo = document.querySelector('.link-video');
        const modalVideo = document.querySelector('.modal-video');
        const iframeModal = document.querySelector('.iframe-modal');
        const closeModalVideo = document.querySelector('.close-modal-video');

        linkVideo.addEventListener('click', (event) => {
            event.preventDefault();
            const videoUrl = linkVideo.getAttribute('data-urlvideo');
            iframeModal.src = videoUrl;
            modalVideo.classList.remove('hidden');
            modalVideo.classList.add('flex');
        });

        closeModalVideo.addEventListener('click', (event) => {
            event.preventDefault();

            iframeModal.src = '';
            modalVideo.classList.add('hidden');
            modalVideo.classList.remove('flex');
        });
    }

    if (document.querySelectorAll(".parent-links .links a")) {
        const links = document.querySelectorAll(".parent-links .links a");
        const contentItems = document.querySelectorAll(".parent-links .content .open-retail");
    
        links.forEach((link, index) => {
            link.addEventListener("click", function (e) {
                const sibling = contentItems[index];
                if (sibling) {
                    e.preventDefault();
                    sibling.classList.toggle("active-open");
                    if (sibling.classList.contains("active-open")) {
                        sibling.style.maxHeight = sibling.scrollHeight + "px";
                    } else {
                        sibling.style.maxHeight = null;
                    }
                    links.forEach((otherLink, i) => {
                        if (i === index) {
                            link.classList.toggle("active-open");
                        } else {
                            otherLink.classList.remove("active-open");
                        }
                    });
                    contentItems.forEach((item, i) => {
                        if (i !== index && item.classList.contains("active-open")) {
                            item.classList.remove("active-open");
                            item.style.maxHeight = null;
                        }
                    });
                }
            });
        });
    }

    if (document.querySelectorAll(".parent-linksm .linksm a")) {
        const links = document.querySelectorAll(".parent-linksm .linksm a");
        const contentItems = document.querySelectorAll(".parent-linksm .contentm .open-retail");
        links.forEach((link, index) => {
            link.addEventListener("click", function (e) {
                const sibling = contentItems[index];
                if (sibling) {
                    e.preventDefault();
                    sibling.classList.toggle("active-open");
                    if (sibling.classList.contains("active-open")) {
                        sibling.style.maxHeight = sibling.scrollHeight + "px";
                    } else {
                        sibling.style.maxHeight = null;
                    }
                    links.forEach((otherLink, i) => {
                        if (i === index) {
                            link.classList.toggle("active-open");
                        } else {
                            otherLink.classList.remove("active-open");
                        }
                    });
                    contentItems.forEach((item, i) => {
                        if (i !== index && item.classList.contains("active-open")) {
                            item.classList.remove("active-open");
                            item.style.maxHeight = null;
                        }
                    });
                }
            });
        });
    }

    if (document.querySelectorAll(".box-tabs")) {
        const boxTabs = document.querySelectorAll(".box-tabs");
        const openRetail2 = document.querySelectorAll(".open-retail2");

        function showTab(index) {
            boxTabs.forEach(function(tab, i) {
                if (i === index) {
                    tab.classList.add("active");
                } else {
                    tab.classList.remove("active");
                }
            });

            openRetail2.forEach(function(retail, i) {
                if (i === index) {
                    retail.classList.add("fade-in");
                    retail.classList.remove("fade-out");
                    retail.style.display = "block";
                } else {
                    retail.classList.remove("fade-in");
                    retail.classList.add("fade-out");
                    setTimeout(() => {
                    retail.style.display = "none";
                    }, 200);
                }
            });
        }

        boxTabs.forEach(function(tab, index) {
            tab.addEventListener("click", function() {
                const tabIndex = parseInt(tab.getAttribute("data-tab-index"), 10);
                showTab(tabIndex);
            });
        });
        
        showTab(0);
    }

    if (document.querySelectorAll(".box-tabs-mobile")) {
        const boxTabsm = document.querySelectorAll(".box-tabs-mobile");
        const openRetail2m = document.querySelectorAll(".open-retail2");

        function showTab(index) {
            boxTabsm.forEach(function(tab, i) {
                if (i === index) {
                    tab.classList.add("active");
                } else {
                    tab.classList.remove("active");
                }
            });

            openRetail2m.forEach(function(retail, i) {
                if (i === index) {
                    retail.classList.add("fade-in");
                    retail.classList.remove("fade-out");
                    retail.style.display = "block";
                } else {
                    retail.classList.remove("fade-in");
                    retail.classList.add("fade-out");
                    setTimeout(() => {
                    retail.style.display = "none";
                    }, 200);
                }
            });
        }

        boxTabsm.forEach(function(tab, index) {
            tab.addEventListener("click", function() {
                const tabIndex = parseInt(tab.getAttribute("data-tab-index"), 10);
                showTab(tabIndex);
                console.log(tabIndex);
            });
        });
        
        showTab(0);
    }

    const homeOrderSelect = document.querySelector('#home-order-select');
    if (homeOrderSelect) {
        homeOrderSelect.addEventListener('change', () => {
            const selectedURL = new URL(homeOrderSelect.value);
            const currentURLParams = new URLSearchParams(window.location.search);
            const selectedURLParams = new URLSearchParams(selectedURL.search);
    
            // Iteramos sobre cada parámetro en selectedURL y lo establecemos o reemplazamos en currentURLParams.
            for (const [key, value] of selectedURLParams) {
                currentURLParams.set(key, value);
            }
    
            // Construimos la URL final
            const finalURL = selectedURL.origin + selectedURL.pathname + '?' + currentURLParams.toString();
            window.location.href = finalURL;
        });
    }    

    //if #home-posts exists and url have order or page params scroll to #home-posts
    const homePosts = document.querySelector('#home-posts');
    if (homePosts) {
        const urlParams = new URLSearchParams(window.location.search);
        
        const order = urlParams.get('order');
        const page = urlParams.get('page');

        if (order || page) {
            homePosts.scrollIntoView();
        }
    }

    // Copiar al portapapeles
    document.querySelectorAll('.copy-link-btn').forEach(function(element) {
        element.addEventListener('click', function(event) {
            event.preventDefault();
            copyToClipboard(window.location.href);
        })
       
    });

    // Abrir en pop-up
    document.querySelectorAll('.share-popup').forEach(function(element) {
        element.addEventListener('click', function(event) {
            event.preventDefault();
            openPopup(event.currentTarget.href, 600, 400); // Cambio a currentTarget
        });
    });

    const recipeForms = document.querySelectorAll('.recipe-form');
    let isAjax = false;

    recipeForms.forEach(function(form) {
        const messageContainer = form.querySelector('#message-container');

        form.addEventListener('submit', function(e) {
            e.preventDefault();

            if (isAjax) {   
                return;
            }

            let currentForm = e.target;

            isAjax = true;

            let formData = new FormData(form);

            fetch(form.getAttribute('action'), {
                method: form.getAttribute('method'),
                body: formData,
            })
            .then(response => response.json())
            .then(response => {
                isAjax = false;
                
                if (window.createRecaptchaToken) window.createRecaptchaToken();
        
                if (response.success) {
                    currentForm.reset();
                    messageContainer.innerHTML = `<div class="alert alert-success">${response.data.message}</div>`;
                } else {
                    messageContainer.innerHTML = `<div class="alert alert-error">${response.data.message}</div>`;
                }
            })
            .catch((error) => {
                isAjax = false;
                if (messageContainer) messageContainer.innerHTML = `<div class="alert alert-error">Error en la conexión.</div>`;
            
                if (window.createRecaptchaToken) window.createRecaptchaToken();
            });
        });
    });


    // Función para realizar la petición AJAX y actualizar la lista de productos
    /**
     * @param {FormData} data - Los datos del formulario
     * @returns {void}
     * */
    function fetchAndUpdateProducts(data) {
        fetch(ajaxUrl, {
            method: 'POST',
            body: data,
        })
        .then(response => response.text())
        .then(html => {
            // Actualizar el contenedor de elementos de productos con el nuevo HTML
            const productItemsContainer = document.querySelector('#product-items-container');
            productItemsContainer.innerHTML = html;

            updatePaginationEventListeners(); // Actualizar los eventos de paginación
            
            // Obtener el número de posts encontrados desde los datos del contenedor de resultados
            const productResults = document.querySelector('#product-results');
            const foundPosts = productResults.dataset.foundPosts;
            
            // Actualizar el elemento que muestra la cantidad de posts encontrados
            document.querySelector('#found-posts').textContent = foundPosts;

            initializeTooltips();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    // Función para recopilar los términos seleccionados y realizar la petición AJAX
    function getSelectedTermsAndFetch(page = 1) {
        const filterContainer = getFilterContainer(); //document.querySelector('#desktop-filter, #mobile-filter'); //contenedor de filtros
        const selectedProductTermsValues = []; //IDs de términos seleccionados

        let  selectedProductTerms = [];
        if (filterContainer) {
            selectedProductTerms = filterContainer.querySelectorAll('input[type="checkbox"]:checked'); //checkboxes seleccionados
        }

        selectedProductTerms.forEach(function(selectedProductTerm) {
            selectedProductTermsValues.push(selectedProductTerm.value);
        });

        const data = new FormData();
        //data.append('action', 'filter_products');
        data.append('product_terms', selectedProductTermsValues); // Si tu backend espera un string separado por comas
        data.append('page', page); // Siempre envía la página actual

        //agregar o reemplazar datos en form#pagination-info
        const paginationInfoForm = document.querySelector('#filter-info');
        if (paginationInfoForm) {
            const paginationInfoData = new FormData(paginationInfoForm);
            paginationInfoData.forEach(function(value, key) {
                data.append(key, value);
            });
        }

        fetchAndUpdateProducts(data);
    }

    // Función para manejar el evento de clic en el paginado
    function handlePaginationClick(e) {
        e.preventDefault();
        const page = this.dataset.page || 1; // Obtén el número de página del atributo data-page del enlace
        getSelectedTermsAndFetch(page); // Llama a la función con el número de página correspondiente
    }

    // Activar paginado con ajax
    function bindPaginationEvents() {
        const ajaxPaginationLinks = document.querySelectorAll('.ajax-pagination a');
        ajaxPaginationLinks.forEach(link => {
            link.removeEventListener('click', handlePaginationClick);
            link.addEventListener('click', handlePaginationClick);
        });
    };

    // Llamar a esta función para actualizar los eventos de paginación después de cada petición AJAX
    function updatePaginationEventListeners() {
        bindPaginationEvents();
    }

    // Llama a esta función cada vez que actualices el contenedor de paginación con nueva respuesta AJAX
    updatePaginationEventListeners();

    // Encuentra y establece el evento de clic en los botones de limpiar
    document.querySelectorAll('.clear-selected-product-terms').forEach(clearButton => {
        clearSelectedTerms(clearButton);

        updateClearButtonVisibility();
    });

    // Asignación de evento change a los checkboxes de términos de producto
    const filterContainer = getFilterContainer();
    if (filterContainer) {
        filterContainer.querySelectorAll('.product-term').forEach(function(checkbox) {
            checkbox.addEventListener('change', function() {
                handleProductTermChange(this);
                getSelectedTermsAndFetch(); // No se necesita pasar la página aquí, se usará el valor predeterminado de 1
            });

            if (checkbox.checked) {
                handleProductTermChange(checkbox);
            }
        });
    }

    // Función para agregar o eliminar términos seleccionados
    function handleProductTermChange(productTerm) {
        const selectedProductTerms = document.querySelector('.selected-product-terms'); // Contenedor para los tags

        if (productTerm.checked) {
            // Si el checkbox está seleccionado, agregar un tag
            const selectedProductTerm = document.createElement('span');
            selectedProductTerm.className = 'tab bg-white py-[.6rem] px-[1rem] rounded-[2.4rem] text-redbb leading-[1] text-[1.3rem] flex items-center gap-x-[1rem]';
            selectedProductTerm.setAttribute('id', 'tag-' + productTerm.value);

            selectedProductTerm.innerHTML = `${productTerm.dataset.name}<img src="${closeredSrc}" alt="ico" class="closetab">`;
            selectedProductTerm.dataset.value = productTerm.value;

            // Agregar evento para eliminar el tag y deseleccionar el término
            const closeButton = selectedProductTerm.querySelector('.closetab');
            closeButton.addEventListener('click', function(e) {
                e.preventDefault();

                productTerm.checked = false;
                productTerm.closest('.acc').classList.remove('active-parent');
                this.closest('span').remove();

                // Simular evento change en el checkbox para actualizar los resultados
                const changeEvent = new Event('change');
                productTerm.dispatchEvent(changeEvent);
            });

            if (selectedProductTerms) selectedProductTerms.appendChild(selectedProductTerm);
        } else {
            // Si el checkbox no está seleccionado, eliminar el tag correspondiente
            const existingTerm = selectedProductTerms.querySelector(`span[data-value="${productTerm.value}"]`);
            if (existingTerm) existingTerm.remove();
        }

        //Si hay términos seleccionados, agregar en el botón #apply-filter texto de color blanco con tailwind y la clase bg-red
        const applyFilterButton = document.querySelector('#apply-filter');
        if (applyFilterButton) {
            if (selectedProductTerms.children.length > 0) {
                applyFilterButton.classList.add('text-white');
                applyFilterButton.classList.add('bg-red');
                
            } else {
                applyFilterButton.classList.remove('text-white');
                applyFilterButton.classList.remove('bg-red');
            }

            updateClearButtonVisibility();
        }
    }

    //Mostrar / ocultar botón de limpiar
    function updateClearButtonVisibility() {
        const clearButtons = document.querySelectorAll('.clear-selected-product-terms');
        
        clearButtons.forEach(function(element) {
            //Contar checkboxes seleccionados
            const filterContainer = getFilterContainer();
            const selectedProductTerms = filterContainer.querySelectorAll('input[type="checkbox"]:checked');
            
            if (selectedProductTerms) {
                if (selectedProductTerms.length > 0) {
                    element.classList.remove('hidden');
                } else {
                    element.classList.add('hidden');
                }
            }
        });
    }
    
    //Estado inicial del botón de limpiar
    updateClearButtonVisibility();

    const form = document.querySelector('.mini-contact-form');

    if (form) {
        const formMessage = document.getElementById('form-message');
        formMessage.classList.add('hidden');
        
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            const formData = new FormData(form);
            const url = form.getAttribute('action');
    
            fetch(url, {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
                if(data.success){
                    // Actualiza el mensaje de éxito
                    formMessage.innerHTML = '<div style="color: green;">¡Mensaje enviado con éxito!</div>';
                    form.reset();
                } else {
                    // Actualiza el mensaje de error
                    formMessage.innerHTML = '<div style="color: red;">Ocurrió un error al enviar el mensaje.</div>';
                }

                formMessage.classList.remove('hidden');
            })
            .catch(error => {
                formMessage.classList.remove('hidden');
                formMessage.innerHTML = '<div style="color: red;">Error de red o servidor no disponible.</div>';
            });
        });
    }

    const reviewForm = document.querySelector('#review-form');
    if (reviewForm) {
        let isAjax = false;
        reviewForm.addEventListener('submit', function(e) {
            e.preventDefault();

            const starRate = document.getElementById('starrate').getAttribute('data-val')
            if (isAjax) return;
            if (starRate == 0) return;

            isAjax = true;
            
            const formData = new FormData(this);
            formData.append('action', 'submit_comment');
            formData.append('rating', starRate);
        
            fetch(reviewForm.getAttribute('action'), {
                method: reviewForm.getAttribute('method'),
                body: formData
            })
            .then(response => response.json())
            .then(data => {
                isAjax = false;
                if (data.success) {
                    const messageContainer = document.querySelector('.message-container');
                    const closeModalStar = document.querySelector('.close-modal-star');

                    closeModalStar.click();
                    reviewForm.reset();
                }
            })
            .catch((error) => {
                isAjax = false;
                console.error('Error:', error);
            });
        });
    }

    document.querySelectorAll('#two-step-form, #pqrs-form')
        .forEach(function(form) {
            form.addEventListener('submit', function(event) {

            event.preventDefault();
            const messageContainer = document.querySelector('#ajax-message');
            if (messageContainer) messageContainer.classList.add('hidden');

            console.log('#test', form.checkValidity());
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
                
                return;
            }

            if (isAjax) return;
            isAjax = true;

            const modals = document.querySelector(".modal-form");
            const formData = new FormData(this);

            //Texto de enviando animado al boton submit
            const submitButton = form.querySelector('#submitForm span');
            const submitButtonText = submitButton.textContent || 'Enviar';

            if (submitButton) submitButton.textContent = 'Enviando...';

            fetch(this.getAttribute('action'), {
                method: this.getAttribute('method'),
                body: formData
            })
            .then(response => response.json())
            .then(response => {
                if (window.createRecaptchaToken) window.createRecaptchaToken();
                if (response.success) {
                    let number = modals.querySelector('.number');
                    if (number) {
                        number.textContent = response.data.id;
                    } 
        
                    modals.classList.remove("hidden");
                    modals.classList.add("flex");
        
                    const filePreview = form.querySelector('.file-preview');
                    if (filePreview) filePreview.innerHTML = '';

                    form.reset();    
                } else {
                    if (messageContainer && response.data.message) {
                        messageContainer.innerHTML = `<div class="alert alert-error">${response.data.message}</div>`;
                        messageContainer.classList.remove('hidden');
                    }
                }

                if (submitButton) submitButton.textContent = submitButtonText;

                isAjax = false;
            })
            .catch(error => {
                isAjax = false;
                console.error('Error:', error);
            });
        });
    });


    const department = document.querySelector('#department');
    if (department) {
        //on change
        department.addEventListener('change', function() {

            const municipality = document.querySelector('#municipality');
            const data = department.options[department.selectedIndex].dataset.municipalities;

            municipality.innerHTML = '';
            
            if (data) {
                const municipalities = JSON.parse(data);

                console.log('#M ', municipalities);
                console.log(municipalities);

                municipality.innerHTML = '';

                municipality.innerHTML += `<option value=""></option>`;
                if (municipalities) {
                    municipalities.forEach(function(item) {
                        municipality.innerHTML += `<option value="${item.nombre}">${item.nombre}</option>`;
                    });
                }
            }
        });
    }

    document.querySelectorAll('.sort-participants').forEach(function(button) {
        button.addEventListener('click', function() {
            const groupId = this.dataset.groupId;

            sortParticipants(groupId, this.value);
        });
    });

    document.querySelectorAll('.sort-participants').forEach(function(button) {
        const groupId = button.dataset.groupId;
        sortParticipants(groupId, 'asc');
    });
});

// Función para limpiar los términos seleccionados
function clearSelectedTerms(clearButton) {
    clearButton.addEventListener('click', function(e) {
        e.preventDefault();
        const filterContainer = getFilterContainer();
        const selectedProductTerms = document.querySelector('.selected-product-terms');

        // Desmarcar todos los checkboxes
        const checkboxes = filterContainer.querySelectorAll('input[type="checkbox"]:checked');
        checkboxes.forEach(function(checkbox) {
            checkbox.checked = false;
            checkbox.closest('.acc').classList.remove('active-parent');
        });

        const changeEvent = new Event("change");
        const firstCheckbox = filterContainer.querySelector(".product-term");
        firstCheckbox.dispatchEvent(changeEvent);

        if (selectedProductTerms) selectedProductTerms.innerHTML = '';
    });
}


function getFilterContainer() {
    // Uso de window.matchMedia para verificar el ancho del viewport
    if (window.matchMedia("(min-width: 768px)").matches) {
      // Si el ancho del viewport es mayor a 768px, asumimos que es desktop
      return document.querySelector('#desktop-filter');
    } else {
        console.log('mobile');
      // Si el ancho es menor a 768px, asumimos que es móvil
      return document.querySelector('#mobile-filter');
    }
}

function copyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    //alert('Link copied to clipboard!');
}

function openPopup(url, width, height) {
    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;
    const options = `
        scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=${width},height=${height},top=${top},left=${left}
    `;
    window.open(url, '', options);
}

function sortParticipants(groupId, order) {
    const containerId = `participants-container-${groupId}`;
    const participantsContainer = document.getElementById(containerId);
    let participants = Array.from(participantsContainer.children);

    participants.sort((a, b) => {
        let textA = a.querySelector('h2').textContent.trim(); // Assuming the title is in an <h2> element
        let textB = b.querySelector('h2').textContent.trim();
        return order === 'asc' ? textA.localeCompare(textB) : textB.localeCompare(textA);
    });

    participants.forEach(participant => participantsContainer.appendChild(participant));
}